import { useState } from 'react';

import './style.css';
import { useNavigate } from 'react-router-dom';
import { postTournament } from 'api/tournaments';
import { DateInput } from 'components/AddPredictionForm/components/DateInput';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { UserSearch } from 'components/UserSearch';
import { type PostTournament } from 'types/tournaments';
import { type UserSummary } from 'types/user';

export const AddTournament = () => {
  const navigate = useNavigate();

  // Page Status
  const [placingTournament, setPlacingTournament] = useState(false);

  // Tournament Content
  const [tournamentTitle, setTournamentTitle] = useState<string>('');
  const [tournamentGoLiveTime, setTournamentGoLiveTime] = useState<Date>(
    new Date(Date.now())
  );
  const [tournamentCloseTime, setTournamentCloseTime] = useState<Date>(
    new Date(Date.now())
  );
  const [numberOfRounds, setNumberOfRounds] = useState<string>('1');
  const [chosenAuthor, setChosenAuthor] = useState<UserSummary>();
  const [isPromotional, setIsPromotional] = useState(false);
  const [termsLink, setTermsLink] = useState<string>('');
  const [rewardString, setRewardString] = useState<string>('');
  const [numberOfWinners, setNumberOfWinners] = useState<string>('2');
  const [prizeWinnings, setPrizeWinnings] = useState<Array<string>>([]);
  const [isHidden, setIsHidden] = useState(false);

  const isPromotionalTournament = () => {
    return isPromotional && termsLink && rewardString;
  };

  const winningsValid = () => {
    if (isPromotionalTournament()) {
      if (prizeWinnings.length != Number(numberOfWinners)) {
        return false;
      }
      const possibleWinners = new Set([1, 2, 4, 8, 16]);
      if (!possibleWinners.has(prizeWinnings.length)) {
        return false;
      }
      for (const prize of prizeWinnings) {
        if (!Number.isInteger(Number(prize)) || Number(prize) < 1) {
          return false;
        }
      }
    }
    return true;
  };

  const submitTournamentEnabled = () => {
    return (
      tournamentGoLiveTime &&
      tournamentCloseTime &&
      tournamentTitle &&
      chosenAuthor &&
      Number(numberOfRounds) &&
      (isPromotionalTournament() || !isPromotional) &&
      winningsValid()
    );
  };

  const submitTournament = async () => {
    if (!chosenAuthor) {
      console.error('Error adding tournament: no author chosen for tournament');
      return;
    }
    const postBody: PostTournament = {
      tournamentTitle: tournamentTitle,
      numberOfRounds: Number(numberOfRounds),
      tournamentGoLiveTime: tournamentGoLiveTime,
      tournamentCloseTime: tournamentCloseTime,
      authorUserId: chosenAuthor.userId,
      privacyStatus: isHidden ? 'hidden' : 'public',
    };
    const prizeWinningsMap = new Map(
      prizeWinnings.map((val, index) => [index + 1, Number(val)])
    );
    if (isPromotionalTournament()) {
      postBody.promotionInfo = {
        rewardString,
        termsLink,
        prizeWinnings: Object.fromEntries(prizeWinningsMap),
      };
    }

    // TODO[#56]: Optimistic Mutation?
    setPlacingTournament(true);
    try {
      await postTournament(postBody);
    } catch (err) {
      console.error('Error adding tournament.', err);
    }
    // TODO[#57]: Invalidate Tournament query once view tournaments its completed.
    setPlacingTournament(false);
    navigate('/viewTournaments');
  };

  const clearAllFields = async () => {
    console.error('Should clear');
  };

  return (
    <div className="add-tournament-container">
      <h2>Add Tournament</h2>
      <div>
        <p>Tournament Title</p>
        <Input
          id={'Tournament title field'}
          accessibilityLabel={'Tournament title input'}
          value={tournamentTitle}
          handleOnChange={setTournamentTitle}
        />
      </div>
      <div>
        <p>Tournament Go Live Time</p>
        <DateInput
          date={tournamentGoLiveTime}
          setDate={setTournamentGoLiveTime}
          disabled={placingTournament}
          showTime={true}
        />
      </div>
      <div>
        <p>Tournament Close Time</p>
        <DateInput
          date={tournamentCloseTime}
          setDate={setTournamentCloseTime}
          disabled={placingTournament}
          showTime={true}
        />
      </div>
      <div>
        <p>Number of Rounds in Tournament</p>
        <Input
          id={'Tournament rounds field'}
          accessibilityLabel={'Tournament max rounds input'}
          value={numberOfRounds}
          placeholder={typeof numberOfRounds == 'string' ? numberOfRounds : ''}
          handleOnChange={setNumberOfRounds}
        />
      </div>
      <div>
        <p>Tournament Author</p>
        <UserSearch
          chosenAuthor={chosenAuthor}
          setChosenAuthor={setChosenAuthor}
        />
        {chosenAuthor && <p></p>}
      </div>
      <div>
        <label>
          Is this a promotional tournament?
          <input
            type="checkbox"
            aria-label={'Tournament promotion checkbox'}
            checked={isPromotional}
            onChange={() => {
              setIsPromotional(!isPromotional);
            }}
          />
          {isPromotional && (
            <div>
              <div>
                <p>Terms Link</p>
                <Input
                  id={'Tournament promotion terms link field'}
                  accessibilityLabel={'Promotion terms link input'}
                  value={termsLink}
                  handleOnChange={setTermsLink}
                />
              </div>
              <div>
                <p>Reward String</p>
                <Input
                  id={'Pool promotion reward string field'}
                  accessibilityLabel={'Promotion reward input'}
                  value={rewardString}
                  handleOnChange={setRewardString}
                />
              </div>
              <div>
                <p>Number of Winners</p>
                <Input
                  id={'Tournament number of winners field'}
                  accessibilityLabel={'Tournament number of winners input'}
                  value={numberOfWinners}
                  placeholder={
                    typeof numberOfWinners == 'string' ? numberOfWinners : ''
                  }
                  handleOnChange={setNumberOfWinners}
                />
              </div>
              <div>
                <p>Prize Winnings</p>
                {Array.from(
                  { length: Number(numberOfWinners) },
                  (_, idx: number) => (
                    <Input
                      accessibilityLabel={`Prize Winnings ${idx}`}
                      id={`Prize Winnings ${idx}`}
                      key={`Prize Winnings ${idx}`}
                      value={prizeWinnings[idx]}
                      handleOnChange={(val) => {
                        prizeWinnings[idx] = val;
                        setPrizeWinnings([...prizeWinnings]);
                      }}
                      error={
                        Number.isInteger(Number(prizeWinnings[idx]))
                          ? ''
                          : 'Must enter valid integer'
                      }
                    />
                  )
                )}
              </div>
            </div>
          )}
        </label>
      </div>
      <div>
        <label>
          Is this a hidden tournament?
          <input
            type="checkbox"
            aria-label={'Tournament privacy checkbox'}
            checked={isHidden}
            onChange={() => {
              setIsHidden(!isHidden);
            }}
          />
        </label>
      </div>
      <Button
        accessibilityLabel="Create Tournament Button"
        handleOnClick={() => {
          setPlacingTournament(true);
          submitTournament();
          clearAllFields();
        }}
        type="primary"
        text="Create Tournament"
        disabled={!submitTournamentEnabled() || placingTournament}
        loading={placingTournament}
      />
    </div>
  );
};

export default AddTournament;
