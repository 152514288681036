import axios from 'axios';
import { getApiUrls } from 'config/api';
import {
  type Community,
  type CommunityMemberStatus,
  type CreateCommunityBody,
} from 'types/community';
import type { Team } from 'types/sportsdata';

const { API_URL } = getApiUrls();

export const createCommunity = async (body: CreateCommunityBody) => {
  const { data } = await axios.post(`${API_URL}/community`, body);
  return data;
};

type BaseCommunitiesInput = {
  cursor?: string;
  queryLimit?: number;
};

export const getCommunities = async ({
  cursor = '',
  queryLimit = 100,
}: BaseCommunitiesInput) => {
  let url = `${API_URL}/communities?queryLimit=${queryLimit}&sortType=alphabetical&includeJoined=true`;
  if (cursor) {
    url = url + `&cursor=${cursor}`;
  }
  const { data } = await axios.get(url);
  return data.object;
};

export type GetCommunityDetailsOutput = Community & {
  memberStatus: CommunityMemberStatus;
  teamTags: Team[];
};

export const getCommunityDetails = async (communityId: string) => {
  const url = `${API_URL}/community/${communityId}`;
  const { data } = await axios.get(url);
  return data.object;
};

export const GetCommunityUserOption = {
  Users: 'users',
  Invited: 'invited',
  FollowRequests: 'requests',
  Blocked: 'blocked',
} as const;

export type GetCommunityUserOption =
  (typeof GetCommunityUserOption)[keyof typeof GetCommunityUserOption];

type GetUserActionInput = {
  communityId: string;
  filter: GetCommunityUserOption;
};

export const getCommunityUsers = async (input: GetUserActionInput) => {
  const { filter, communityId } = input;
  const url = `${API_URL}/community/${communityId}/${filter}`;
  const { data } = await axios.get(url);
  return data.object;
};

export type CommunityDetailsBody = {
  communityId: string;
  updatedCommunityDetails?: UpdatedCommunityDetails;
  adminToAdd?: string;
  adminToRemove?: string;
};

type UpdatedCommunityDetails = {
  description?: string;
  communityLogo?: string;
};

export const updateCommunityDetails = async ({
  communityId,
  updatedCommunityDetails,
  adminToAdd,
  adminToRemove,
}: CommunityDetailsBody) => {
  const requestBody: {
    description?: string;
    communityLogo?: string;
    adminToAdd?: string;
    adminToRemove?: string;
  } = {};

  if (updatedCommunityDetails) {
    if (updatedCommunityDetails.description) {
      requestBody.description = updatedCommunityDetails.description;
    }
    if (updatedCommunityDetails.communityLogo) {
      requestBody.communityLogo = updatedCommunityDetails.communityLogo;
    }
  }

  if (adminToAdd) {
    requestBody.adminToAdd = adminToAdd;
  }

  if (adminToRemove) {
    requestBody.adminToRemove = adminToRemove;
  }

  const response = await axios.patch(
    `${API_URL}/community/${communityId}`,
    requestBody
  );
  return response;
};

export const CommunityRequestResponse = [`Accept`, `Decline`] as const;
export type CommunityRequestResponse =
  (typeof CommunityRequestResponse)[number];

export type CommunityRequestBody = {
  respondingUserId: string;
  requestingUserId: string;
  response: CommunityRequestResponse;
};

export type CommunityInviteResponseBody = {
  userId: string;
  response: string;
};

export const respondToCommunityRequest = async (
  communityId: string,
  communityResponse: CommunityRequestBody
) => {
  const url = `${API_URL}/communityRequest/${communityId}/respond`;
  const { data } = await axios.post(url, communityResponse);
  return data.object;
};

export const inviteUsersToCommunity = async (
  communityId: string,
  userIds: { userIds: string[] }
) => {
  const url = `${API_URL}/community/${communityId}/invite`;
  const { data } = await axios.post(url, userIds);
  return data.object;
};

export const TakeUserAction = {
  Block: 'block',
  Unblock: 'unblock',
} as const;

export type TakeUserAction =
  (typeof TakeUserAction)[keyof typeof TakeUserAction];

type UserActionInput = {
  communityId: string;
  userId: string;
};

type TakeUserActionInput = UserActionInput & {
  reaction: TakeUserAction;
};

export const takeActionOnUser = async (input: TakeUserActionInput) => {
  const { reaction, userId, communityId } = input;
  const url = `${API_URL}/community/${communityId}/${reaction}/${userId}`;
  const { data } = await axios.post(url);
  return data.object;
};
