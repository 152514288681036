import {
  type CreateComparisonPrediction,
  type Future,
} from '../components/AddPredictionForm/types';
import {
  CBB_SEASONS,
  CFB_SEASONS,
  CFB_TEAM_METRICS,
  CWBB_SEASONS,
  MLB_PITCHER_METRICS,
  MLB_PLAYER_METRICS,
  MLB_SEASONS,
  MLB_TEAM_METRICS,
  NBA_METRICS,
  NBA_PER_GAME_METRICS,
  NBA_SEASONS,
  NFL_DEFENSE_METRICS,
  NFL_KICKER_METRICS,
  NFL_OTHER_OFF_METRICS,
  NFL_OTHER_ST_METRICS,
  NFL_PUNTER_METRICS,
  NFL_QB_METRICS,
  NFL_RB_METRICS,
  NFL_SEASONS,
  NFL_TEAM_METRICS,
  NFL_WR_METRICS,
  NHL_SEASONS,
  OUTCOME_GAME_METRICS,
  OUTCOME_SEASON_METRICS,
  WNBA_METRICS,
  WNBA_SEASONS,
} from 'constants/.';
import { CBB_PLAYER_FUTURES } from 'constants/futures/cbb/cbbPlayerFutures';
import { CBB_TEAM_FUTURES } from 'constants/futures/cbb/cbbTeamFutures';
import { CFB_TEAM_FUTURES } from 'constants/futures/cfb/cfbTeamFutures';
import { CWBB_TEAM_FUTURES } from 'constants/futures/cwbb/cwbbTeamFutures';
import { MLB_PLAYER_FUTURES } from 'constants/futures/mlb/mlbPlayerFutures';
import { MLB_TEAM_FUTURES } from 'constants/futures/mlb/mlbTeamFutures';
import { NBA_PLAYER_FUTURES } from 'constants/futures/nba/nbaPlayerFutures';
import { NBA_TEAM_FUTURES } from 'constants/futures/nba/nbaTeamFutures';
import { NFL_PLAYER_FUTURES } from 'constants/futures/nfl/nflPlayerFutures';
import { NFL_TEAM_FUTURES } from 'constants/futures/nfl/nflTeamFutures';
import { NHL_PLAYER_FUTURES } from 'constants/futures/nhl/nhlPlayerFutures';
import { NHL_TEAM_FUTURES } from 'constants/futures/nhl/nhlTeamFutures';
import { WNBA_TEAM_FUTURES } from 'constants/futures/wnba/wnbaTeamFutures';
import { CBB_METRICS, CBB_PER_GAME_METRICS } from 'constants/metrics/cbb';
import { CWBB_METRICS } from 'constants/metrics/cwbb';
import {
  NHL_ALL_METRICS,
  NHL_PLAYER_METRICS,
  NHL_TEAM_METRICS,
} from 'constants/metrics/nhl';
import {
  type ComparisonValue,
  type MetricOption,
  type PredictionCategory,
  type PredictionDuration,
} from 'types/addPrediction';
import {
  type ComparisonChildStatisticPredictionContent,
  type ComparisonPredictionContent,
  type FuturePredictionContent,
  type GameDetails,
  type GameOutcomeMetric,
  type OutcomePredictionContent,
  type PredictionGrouping,
  type PredictionType,
  type SeasonDetails,
  type SeasonOutcomeMetric,
  type StatisticPredictionContent,
  type TeamInfo,
} from 'types/prediction';
import {
  type NhlPlayer,
  type Entity,
  type Game,
  type League,
  type MlbPlayer,
  type NflGame,
  type NflPlayer,
  type Player,
  type Season,
  type Team,
} from 'types/sportsdata';
import { isPlayer } from 'utils/player';
import { filterRegEx } from 'utils/regex';
import {
  convertSportsDataPlayerToPlayerInfo,
  convertSportsDataTeamToTeamInfo,
} from 'utils/sportsDataConversions';
import { isCollegeTeam } from 'utils/teams';

export const parseDateToString = (dateInput: string): string => {
  const date = new Date(dateInput);
  // Get string values
  const dateString = date.toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const timeString = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  });

  return `${dateString} @ ${timeString}`;
};

const cleanSearchString = (search: string) => {
  return search
    .replace(/[^a-z0-9 ]/gi, '')
    .toLowerCase()
    .trim();
};

const getTeamSearchableStrings = (team: Team) => {
  if (isCollegeTeam(team)) {
    const name = team.Name.toLowerCase();
    const nameParts = team.Name.toLowerCase().split(' ');
    const school = team.School.toLowerCase();
    const schoolParts = team.School.toLowerCase().split(' ');
    const shortDisplay = team.ShortDisplayName.toLowerCase();
    return [
      name,
      school,
      school + ' ' + name,
      shortDisplay,
      ...nameParts,
      ...schoolParts,
    ];
  }

  const city = team.City.toLowerCase();
  const cityNameParts = team.City.toLowerCase().split(' ');
  const name = team.Name.toLowerCase();
  return [city, name, city + ' ' + name, ...cityNameParts];
};

export const filterEntities = (
  entities: Array<Player | Team>,
  search: string
) => {
  const filter = entities?.filter((entity) => {
    let entityStrings: string[];
    search = cleanSearchString(search);
    if (isPlayer(entity)) {
      const firstName = entity.FirstName.replace(
        /[^a-z0-9]/gi,
        ''
      ).toLowerCase();
      const lastName = entity.LastName.replace(/[^a-z0-9]/gi, '').toLowerCase();
      entityStrings = [firstName, lastName, firstName + ' ' + lastName];
    } else {
      entityStrings = getTeamSearchableStrings(entity);
    }
    return entityStrings.some((lowerWord) => lowerWord.startsWith(search));
  });
  return filter;
};

export const filterTeams = (teams: Team[], search: string) => {
  const filter = teams?.filter((entity) => {
    search = cleanSearchString(search);
    const entityStrings = getTeamSearchableStrings(entity);
    return entityStrings.some((lowerWord) => lowerWord.startsWith(search));
  });
  return filter;
};

export const filterMetrics = (metrics: MetricOption[], search: string) => {
  const filter = metrics?.filter((metricItem) => {
    const metricValue = metricItem.value.toLowerCase();
    const metricLabel = metricItem.label.toLowerCase();
    const metricLabelParts = metricItem.label.toLowerCase().split(' ');
    const metricStrings = [metricValue, metricLabel, ...metricLabelParts];
    return metricStrings.some((stringItem) =>
      stringItem.startsWith(search.toLowerCase())
    );
  });
  return filter;
};

export const setEntityLabel = (selectedEntity: Player | Team) => {
  if (isPlayer(selectedEntity)) {
    return `${selectedEntity.FirstName} ${selectedEntity.LastName}`;
  } else {
    return setTeamLabel(selectedEntity);
  }
};

export const setTeamLabel = (team: Team) => {
  if (isCollegeTeam(team)) {
    return `${team.School} ${team.Name}`;
  }
  return `${team.City} ${team.Name}`;
};

export const setMetricLabel = (selectedMetric: MetricOption) => {
  return selectedMetric.label;
};

export const setFutureLabel = (selectedFuture: Future) => {
  return selectedFuture.futureDescription;
};

export const getTeamId = (entity: Player | Team | undefined) => {
  if (entity) {
    if (isPlayer(entity)) {
      return entity.GlobalTeamID.toString();
    } else {
      return entity.GlobalTeamID.toString();
    }
  } else {
    return undefined;
  }
};

export const isNflGame = (game: Game): game is NflGame => {
  return (game as NflGame).GameKey !== undefined;
};

export const getMetricItems = (
  league: League,
  predictionCategory: PredictionCategory,
  entity?: Team | Player,
  duration?: PredictionDuration
): MetricOption[] => {
  let metrics: MetricOption[] = [];
  if (predictionCategory == 'future') {
    switch (league) {
      case 'nfl':
        if (entity && isPlayer(entity)) {
          return NFL_PLAYER_FUTURES;
        } else {
          return NFL_TEAM_FUTURES;
        }
      case 'nba':
        if (entity && isPlayer(entity)) {
          return NBA_PLAYER_FUTURES;
        } else {
          return NBA_TEAM_FUTURES;
        }
      case 'cbb':
        if (entity && isPlayer(entity)) {
          return CBB_PLAYER_FUTURES;
        } else {
          return CBB_TEAM_FUTURES;
        }
      case 'mlb':
        if (entity && isPlayer(entity)) {
          return MLB_PLAYER_FUTURES;
        } else {
          return MLB_TEAM_FUTURES;
        }
      case 'cfb':
        return CFB_TEAM_FUTURES;
      case 'cwbb':
        return CWBB_TEAM_FUTURES;
      case 'wnba':
        return WNBA_TEAM_FUTURES;
      case 'nhl':
        if (entity && isPlayer(entity)) {
          return NHL_PLAYER_FUTURES;
        } else {
          return NHL_TEAM_FUTURES;
        }
    }
  }
  if (predictionCategory == 'outcome') {
    if (duration == 'game') {
      return OUTCOME_GAME_METRICS;
    } else {
      return OUTCOME_SEASON_METRICS;
    }
  }
  switch (league) {
    case 'nba':
      if (duration == 'season') {
        metrics = [...NBA_METRICS, ...NBA_PER_GAME_METRICS];
      } else {
        metrics = NBA_METRICS;
      }
      break;
    case 'mlb':
      // Standard Players get all base metrics
      metrics = MLB_PLAYER_METRICS;
      if (entity) {
        if (!isPlayer(entity)) {
          // Teams get base metrics followed by pitching metrics
          metrics = MLB_TEAM_METRICS;
        } else if ((entity as MlbPlayer).PositionCategory === 'P') {
          // Pitchers get pitching metrics followed by base metrics
          metrics = MLB_PITCHER_METRICS;
        }
      }
      break;
    case 'nfl':
      metrics = [];
      if (entity) {
        if (!isPlayer(entity)) {
          metrics = NFL_TEAM_METRICS;
        } else {
          const nflPlayer = entity as NflPlayer; // Casting here for type checking
          if (nflPlayer.PositionCategory === 'DEF') {
            // DEFENSIVE PLAYER METRICS
            metrics = NFL_DEFENSE_METRICS;
          } else if (nflPlayer.PositionCategory === 'OFF') {
            // OFFENSIVE PLAYER METRICS BY POSITION
            if (nflPlayer.Position === 'QB') {
              metrics = NFL_QB_METRICS;
            } else if (nflPlayer.Position === 'WR') {
              metrics = NFL_WR_METRICS;
            } else if (nflPlayer.Position === 'RB') {
              metrics = NFL_RB_METRICS;
            } else {
              metrics = NFL_OTHER_OFF_METRICS;
            }
          } else if (nflPlayer.PositionCategory === 'ST') {
            // SPECIAL TEAM PLAYER METRICS BY POSITION
            if (nflPlayer.Position === 'K') {
              metrics = NFL_KICKER_METRICS;
            } else if (nflPlayer.Position === 'P') {
              metrics = NFL_PUNTER_METRICS;
            } else {
              metrics = NFL_OTHER_ST_METRICS;
            }
          }
        }
      }
      break;
    case 'cbb':
      if (duration == 'season') {
        metrics = [...CBB_METRICS, ...CBB_PER_GAME_METRICS];
      } else {
        metrics = CBB_METRICS;
      }
      break;
    case 'cfb':
      metrics = CFB_TEAM_METRICS;
      break;
    case 'wnba':
      if (duration == 'season') {
        metrics = [];
      } else {
        metrics = WNBA_METRICS;
      }
      break;
    case 'cwbb':
      if (duration == 'season') {
        metrics = [];
      } else {
        metrics = CWBB_METRICS;
      }
      break;
    case 'nhl':
      metrics = [];
      if (entity) {
        if (!isPlayer(entity)) {
          metrics = NHL_TEAM_METRICS;
        } else {
          const nhlPlayer = entity as NhlPlayer; // Casting here for type checking
          metrics = NHL_PLAYER_METRICS;
          if (nhlPlayer.Position === 'G') {
            // GOALIE PLAYER METRICS
            metrics = NHL_ALL_METRICS;
          }
        }
      }
      break;
  }
  return metrics;
};

export const getTeamInfo = (
  teamId: string,
  teams: Team[]
): TeamInfo | undefined => {
  const entity = teams.find((team) => team.TeamID.toString() == teamId);
  if (entity) {
    return convertSportsDataTeamToTeamInfo(entity);
  }
};

type GetStatPredContentInput = {
  entity: Player | Team;
  metric: MetricOption;
  comparison: ComparisonValue;
  value: string;
  leagueTeams: Team[];
};

export const getStatPredContent = ({
  entity,
  metric,
  comparison,
  value,
  leagueTeams,
}: GetStatPredContentInput): StatisticPredictionContent => {
  let content: StatisticPredictionContent;
  if (isPlayer(entity)) {
    // Set content for PlayerGameStatistic
    content = {
      entityId: `${entity.PlayerID}`,
      player: `${entity.FirstName} ${entity.LastName}`,
      metric: metric?.value,
      predictedValue: value,
      comparison: comparison.operator,
      playerInfo: convertSportsDataPlayerToPlayerInfo(entity),
    };
  } else {
    // Set content for TeamGameStatistic
    content = {
      entityId: `${entity.TeamID}`,
      team: setTeamLabel(entity),
      metric: metric?.value,
      predictedValue: value,
      comparison: comparison.operator,
    };
  }
  // If the metric is a per game metric, add the perGame flag
  if (metric && metric.perGame) {
    content.perGame = true;
  }
  // Add team info for both Player & Team statistic predictions
  const teamInfo = getTeamInfo(entity.TeamID.toString(), leagueTeams);
  if (teamInfo) {
    content.teamInfo = teamInfo;
  }
  return content;
};

type GetGameOutcomePredContentInput = {
  team: Team;
  metric: MetricOption;
  value: string;
  leagueTeams: Team[];
  game: Game;
};

export const getGameOutcomePredContent = ({
  team,
  metric,
  value,
  leagueTeams,
  game,
}: GetGameOutcomePredContentInput): OutcomePredictionContent => {
  // Get opposing team info
  const { HomeTeamID, AwayTeamID } = game;
  const oppTeamId = team.TeamID == HomeTeamID ? AwayTeamID : HomeTeamID;
  const oppTeamInfo = getTeamInfo(oppTeamId.toString(), leagueTeams);

  // Set content for TeamGameOutcome
  const content: OutcomePredictionContent = {
    teamInfo: convertSportsDataTeamToTeamInfo(team),
    oppTeamInfo,
    metric: metric.value as GameOutcomeMetric,
  };

  // Add predicted value if there is a spread
  if (metric.value == 'spread') {
    content.predictedValue = value;
  }
  return content;
};

type GetSeasonOutcomePredContentInput = {
  team: Team;
  metric: MetricOption;
  comparison: ComparisonValue;
  value: string;
};

export const getSeasonOutcomePredContent = ({
  team,
  metric,
  comparison,
  value,
}: GetSeasonOutcomePredContentInput): OutcomePredictionContent => {
  // Set content for TeamSeasonOutcome
  const content: OutcomePredictionContent = {
    teamInfo: convertSportsDataTeamToTeamInfo(team),
    metric: metric.value as SeasonOutcomeMetric,
    comparison: comparison.operator,
    predictedValue: value,
  };

  return content;
};

type GetFuturePredContentInput = {
  entity: Entity;
  metric: MetricOption;
  leagueTeams: Team[];
  seasonId: string;
  league: League;
};

export const getFuturePredContent = ({
  entity,
  metric,
  leagueTeams,
  seasonId,
  league,
}: GetFuturePredContentInput): FuturePredictionContent => {
  let content: FuturePredictionContent;
  const teamInfo = getTeamInfo(entity.TeamID.toString(), leagueTeams);
  const futureId = `${league}#${seasonId}#${metric.value}`;
  if (isPlayer(entity)) {
    // Set content for PlayerFuture
    content = {
      entityId: `${entity.PlayerID}`,
      futureDescription: metric.label,
      futureId,
      playerInfo: convertSportsDataPlayerToPlayerInfo(entity),
      teamInfo,
    };
  } else {
    // Set content for TeamFuture
    content = {
      entityId: `${entity.TeamID}`,
      futureDescription: metric.label,
      futureId,
      teamInfo,
    };
  }
  // Add team info for both Player & Team statistic predictions
  if (teamInfo) {
    content.teamInfo = teamInfo;
  }
  return content;
};

type GetStatChildPredContentInput = {
  entity?: Player | Team;
  metric?: MetricOption;
  leagueTeams: Team[];
};

export const getStatComparisonChildPredContent = ({
  entity,
  metric,
  leagueTeams,
}: GetStatChildPredContentInput): ComparisonChildStatisticPredictionContent => {
  let content: ComparisonChildStatisticPredictionContent;
  if (isPlayer(entity)) {
    // Set content for PlayerGameStatistic
    content = {
      entityId: `${entity.PlayerID}`,
      player: `${entity.FirstName} ${entity.LastName}`,
      metric: metric?.value || 'metric',
      playerInfo: convertSportsDataPlayerToPlayerInfo(entity),
    };
  } else {
    // Set content for TeamGameStatistic
    content = {
      entityId: `${entity?.TeamID}`,
      team: entity ? setTeamLabel(entity) : undefined,
      metric: metric?.value || 'metric',
    };
  }
  // If the metric is a per game metric, add the perGame flag
  if (metric && metric.perGame) {
    content.perGame = true;
  }
  // Add team info for both Player & Team statistic predictions
  const teamInfo = getTeamInfo(entity?.TeamID.toString() || '', leagueTeams);
  if (teamInfo) {
    content.teamInfo = teamInfo;
  }
  return content;
};

type GetComparisonPredContentInput = {
  comparisonPrediction: CreateComparisonPrediction;
  comparison: ComparisonValue;
  leagueTeams: Team[];
  league: League;
};

export const getComparisonPredictionContent = ({
  comparisonPrediction,
  comparison,
  leagueTeams,
  league,
}: GetComparisonPredContentInput): ComparisonPredictionContent => {
  const {
    predictionType: prediction1Type,
    gameDetails: gameDetails1,
    seasonDetails: seasonDetails1,
  } = getChildPredDetailsAndType(
    comparisonPrediction.predictions['1'].entity,
    comparisonPrediction.predictions['1'].game,
    comparisonPrediction.predictions['1'].season
  );
  const {
    predictionType: prediction2Type,
    gameDetails: gameDetails2,
    seasonDetails: seasonDetails2,
  } = getChildPredDetailsAndType(
    comparisonPrediction.predictions['2'].entity,
    comparisonPrediction.predictions['2'].game,
    comparisonPrediction.predictions['2'].season
  );
  const content: ComparisonPredictionContent = {
    comparison: 'pred1' + comparison.operator + 'pred2',
    predictions: {
      '1': {
        predictionType: prediction1Type,
        content: getStatComparisonChildPredContent({
          entity: comparisonPrediction.predictions['1'].entity,
          metric: comparisonPrediction.predictions['1'].metric,
          leagueTeams,
        }),
        league,
      },
      '2': {
        predictionType: prediction2Type,
        content: getStatComparisonChildPredContent({
          entity: comparisonPrediction.predictions['2'].entity,
          metric: comparisonPrediction.predictions['2'].metric,
          leagueTeams,
        }),
        league,
      },
    },
  };

  if (gameDetails1) {
    content.predictions['1'].gameDetails = gameDetails1;
  }
  if (seasonDetails1) {
    content.predictions['1'].seasonDetails = seasonDetails1;
  }
  if (gameDetails2) {
    content.predictions['2'].gameDetails = gameDetails2;
  }
  if (seasonDetails2) {
    content.predictions['2'].seasonDetails = seasonDetails2;
  }

  return content;
};

export const getChildPredDetailsAndType = (
  entity?: Entity,
  game?: Game,
  season?: Season
): {
  predictionType: PredictionType;
  gameDetails?: GameDetails;
  seasonDetails?: SeasonDetails;
} => {
  let predictionType: PredictionType = 'PlayerGameStatistic';
  let gameDetails: GameDetails | undefined;
  let seasonDetails: SeasonDetails | undefined;
  if (game) {
    gameDetails = getGameDetails(game);
    if (isPlayer(entity)) {
      predictionType = 'PlayerGameStatistic';
    } else {
      predictionType = 'TeamGameStatistic';
    }
  } else if (season) {
    seasonDetails = getSeasonDetails(season);
    if (isPlayer(entity)) {
      predictionType = 'PlayerSeasonStatistic';
    } else {
      predictionType = 'TeamSeasonStatistic';
    }
  }
  return { predictionType, gameDetails, seasonDetails };
};

export const getGameDetails = (game: Game): GameDetails => {
  let gameStart = game?.DateTimeUTC;
  if (!gameStart) {
    // transform EST date to UTC
    // example = 2024-05-05T15:00:00 => 024-05-05T19:00:00.000Z => 2024-05-05T19:00:00
    const gameStartEst = game?.DateTime;
    const gameStartUtc = new Date(gameStartEst).toISOString();
    gameStart = gameStartUtc.split('.')[0];
  }
  const gameDetails: GameDetails = {
    gameId: `${game?.GlobalGameID}`,
    leagueGameId: `${game?.GameID}`,
    gameStartTime: `${gameStart}Z`,
    status: `${game?.Status}`,
    homeTeam: `${game?.HomeTeam}`,
    awayTeam: `${game?.AwayTeam}`,
    channel: `${game?.Channel}`,
  };
  if (game && isNflGame(game)) {
    gameDetails.scoreId = game.ScoreID.toString();
    delete gameDetails.leagueGameId;
  }
  return gameDetails;
};

export const getSeasonDetails = (season: Season): SeasonDetails => {
  const { seasonId, seasonYear, seasonEndDate } = season;
  return {
    seasonId,
    seasonYear,
    seasonEndDate,
  };
};

export const isValidSpread = (spread: string) => {
  const filtered = filterRegEx(spread, /^(-)?[0-9]\d*(\.5+)$/g);
  if (filtered && filtered == spread) {
    return true;
  }
  return false;
};

export const isValidGame = (game: Game): boolean => {
  return new Date(`${game?.DateTimeUTC}Z`) > new Date();
};

export const getSeasonsForLeague = (league: League): Season[] => {
  switch (league) {
    case 'nfl':
      return NFL_SEASONS;
    case 'mlb':
      return MLB_SEASONS;
    case 'nba':
      return NBA_SEASONS;
    case 'cbb':
      return CBB_SEASONS;
    case 'cfb':
      return CFB_SEASONS;
    case 'wnba':
      return WNBA_SEASONS;
    case 'cwbb':
      return CWBB_SEASONS;
    case 'nhl':
      return NHL_SEASONS;
    default:
      return [];
  }
};

export const isValidSeason = (season: Season): boolean => {
  return new Date(season.seasonEndDate) > new Date();
};

const roundNearest5 = (numb: number) => {
  return Math.round(numb / 5) * 5;
};

export const getSignedNumberMod5 = (numb: number) => {
  return numb >= 0 ? '+' + roundNearest5(numb) : roundNearest5(numb).toString();
};

export const getSignedNumber = (numb: number) => {
  return numb >= 0 ? '+' + numb : numb.toString();
};

export const getSpreadString = (
  team: Team | undefined,
  game: Game | undefined
) => {
  if (game && team && game.PointSpread != null) {
    const spread = game.PointSpread;
    if (spread == 0) {
      return 'even';
    }
    if (team.GlobalTeamID == game.GlobalHomeTeamID) {
      return `${getSignedNumber(spread)}`;
    }
    if (team.GlobalTeamID == game.GlobalAwayTeamID) {
      return `${getSignedNumber(spread * -1)}`;
    }
  }
  return '';
};

export const metricIsCompatible = (
  options?: MetricOption[],
  metric?: MetricOption
) => {
  const metricInOptions = options?.filter(
    (option) => option.value === metric?.value
  );
  if (metricInOptions && metricInOptions.length > 0) {
    return true;
  }
  return false;
};

export const standardizeUTCDate = (dateInput: string) => {
  const dateEpoch = Date.parse(`${dateInput}Z`);
  return new Date(dateEpoch);
};

export const getPredictionType = (
  category: PredictionCategory,
  duration: PredictionDuration,
  entity: Team | Player
): PredictionType => {
  switch (category) {
    case 'outcome':
      if (duration == 'game') {
        return 'TeamGameOutcome';
      } else {
        return 'TeamSeasonOutcome';
      }
    case 'statistic':
      if (duration == 'game') {
        if (isPlayer(entity)) {
          return 'PlayerGameStatistic';
        } else {
          return 'TeamGameStatistic';
        }
      } else {
        if (isPlayer(entity)) {
          return 'PlayerSeasonStatistic';
        } else {
          return 'TeamSeasonStatistic';
        }
      }
    case 'future':
      if (isPlayer(entity)) {
        return 'PlayerSeasonFuture';
      } else {
        return 'TeamSeasonFuture';
      }
    case 'comparison':
      return 'Comparison';
  }
};

export const getPredictionGrouping = (
  category: PredictionCategory,
  duration: PredictionDuration
): PredictionGrouping => {
  switch (category) {
    case 'outcome':
      if (duration == 'game') {
        return 'GameOutcome';
      } else {
        return 'SeasonOutcome';
      }
    case 'statistic':
      if (duration == 'game') {
        return 'GameStatistic';
      } else {
        return 'SeasonStatistic';
      }
    case 'future':
      return 'SeasonFuture';
    case 'comparison':
      return 'Comparison';
  }
};
